import React, { useEffect } from "react"

import BlogPostTemplate from "../Templates/blogPostTemplate"
import { ContentWrapper } from "../containers/alpacked.style"
import Layout from "../containers/Layout"
import SEO from "../components/SEO"
import homeTemplate from "./index"
import { navigate } from "gatsby"
import { withUnpublishedPreview } from "gatsby-source-prismic"

const FacingCloudTroubles = ({ location }) => {
  useEffect(() => {
    const searchQuery = location.search

    // Define the allowed search queries
    const allowedSearchQueries = [
      "?utm_source=email&utm_medium=Y3Rv",
      "?utm_source=email&utm_medium=Y2Vv",
    ]

    // Check if the current search query is in the allowed search queries
    if (!allowedSearchQueries.includes(searchQuery)) {
      // If not, redirect to the default URL
      navigate("/facing_cloud_troubles?utm_source=email&utm_medium=Y3Rv")
    }
  }, [location.search])

  return (
    <>
      <SEO
        location={location}
        title="Alpacked DevOps Company"
        shortDesc="Alpacked is a DevOps company providing a wide range of end-to-end services - from consulting to managed services. We established a team of top-notch DevOps experts with solid experience not only in cutting edge technologies but in building teams and implementing DevOps practices from scratch."
        description="Alpacked is a DevOps company providing a wide range of end-to-end services - from consulting to managed services. We established a team of top-notch DevOps experts with solid experience not only in cutting edge technologies but in building teams and implementing DevOps practices from scratch."
      />
      <Layout>
        <ContentWrapper>
          <div style={{ marginTop: "10px" }}>
            <iframe
              src="/facing_cloud_troubles.pdf"
              width="100%"
              height="1000"
              description="Embark on DevOps and Cloud transformation!
"
            />
          </div>
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default withUnpublishedPreview(FacingCloudTroubles, {
  templateMap: {
    page: BlogPostTemplate,
    homepage: homeTemplate,
    prismicPage: BlogPostTemplate,
    prismicHomepage: homeTemplate,
  },
})
